import AppChannel from "./channel";
import { HMAccessKeyId } from "../config";
import PubSub from "pubsub-js";
import { EventGameOver, EventGameStart, EventTip, EventQualityList, EventNoOperation } from "./event";
import { getWindowSizeForLandscape, safeParse, getRem, fixByGear } from "./util";
import { MouseBtns } from "../components/customPanel/mouseBtns";

const IgnoreScene = ['delay', 'packetsLost', 'videoStreamInfo']

export const initPlayer = () => {
    try {
        console.log('-- Init Begin --')
        window.Cloudplay.initSDK({
            accessKeyID: HMAccessKeyId,
            channelId: AppChannel,
            onSceneChanged(sceneId, extraInfo) {
                if (IgnoreScene.includes(sceneId)) {
                    return
                }
                console.log(`-- HM Scene Callback:${sceneId} --`, extraInfo)
                if (sceneId === 'stop') {
                    PubSub.publish(EventGameOver, extraInfo)
                }
                if (sceneId === 'play') {
                    PubSub.publish(EventGameStart, extraInfo)
                }
                if (sceneId === 'resolutionList') {
                    PubSub.publish(EventQualityList, extraInfo)
                }
                if (sceneId === 'noInputTimeRemind') {
                    PubSub.publish(EventNoOperation, extraInfo)
                }
                if (sceneId === 'wait') {
                    PubSub.publish(EventTip, { msg: `当前排队${extraInfo.waitingPeople || 1}人，正在尝试连接` })
                }
            },
            MessageHandler(message) {
                console.log(`-- HM Message --`, message)
            }
        })
    } catch (e) {
        console.error('-- Init Failed --', e)
    }
}

export const startPlayer = (el, uId, uToken, pkgName, appChannel, cToken, playingtime, noInputTimeout, protoData, accountData, autoPlayAudio) => {
    window.Cloudplay.startGame(el, {
        userInfo: {
            uId,
            uToken
        },
        pkgName,
        appChannel,
        cToken,
        playingtime,
        configInfo: 'null',
        isPortrait: false,
        isArchive: true,
        noInputTimeout,
        autoPlayAudio,
        protoData,
        ...(accountData && {
            extraData: {
                stringExtra: {
                    StartAppParams: JSON.stringify({ startAppParams: accountData })
                }
            }
        })
    })
}

export const stopPlayer = () => {
    window.Cloudplay.stopGame(result => {
        const { code, msg } = result
        if (code !== 0) {
            PubSub.publish(EventTip, { msg })
        }
    })
}

export const addPlayerTime = (playingTime, cToken, uId, uToken, callback) => {
    playingTime *= 1000
    window.Cloudplay.updateGameUID({
        playingTime,
        cToken,
        userInfo: {
            uId,
            uToken
        }
    }, result => {
        const { code, msg } = result
        if (code !== 0) {
            PubSub.publish(EventTip, { msg })
        } else {
            callback()
        }
    })
}

const OpMethodMap = {
    xbox: 0,
    keyboard: 1,
    NONE: 2
}
const OpMethodArr = ['xbox', 'keyboard', 'NONE']

export const getOpMethod = () => {
    const result = OpMethodMap[window.Cloudplay.getVirtualKeys()]
    return result === void 0 ? -1 : result
}

export const setOpMethod = index => {
    window.Cloudplay.setVirtualKeys(OpMethodArr[index])
}

export const toggleAudioPlay = bool => {
    window.Cloudplay.enableAudio(bool)
}

export const toggleVibrate = bool => {
    window.Cloudplay.enableVibrate(bool)
}

export const switchResolution = id => {
    window.Cloudplay.switchResolution(id)
}

export const resetNoInputTimer = () => {
    window.Cloudplay.resetInputTimer()
}

export const playerKeyDown = key => {
    window.Cloudplay.sendMessageToRom({
        inputOp: key,
        inputState: 2,
        value: 0
    })
}

export const playerMouseDown = dirDown => {
    window.Cloudplay.sendMessageToRom({
        inputOp: 515,
        inputState: 2,
        value: dirDown ? -1 : 1
    })
}

export const playerKeyUp = key => {
    window.Cloudplay.sendMessageToRom({
        inputOp: key,
        inputState: 3,
        value: 0
    })
}

export const playerMouseUp = dirDown => {
    window.Cloudplay.sendMessageToRom({
        inputOp: 515,
        inputState: 3,
        value: dirDown ? -1 : 1
    })
}

const KTMap = {
    1: 'keyboard',
    2: 'macro',
    6: 'joystick',
    7: 'joystick',
    8: 'mouse',
    9: 'mouse',
    10: 'mouse',
    11: 'mouse',
    12: 'mouse'
}

export const restoreOpJson = keyboard => {
    const { width, height } = getWindowSizeForLandscape()
    const rem = getRem()
    const arr = safeParse(keyboard, [])
    const id = Date.now()
    const data = arr.map((k, i) => {
        let btnType = KTMap[k.kT]
        let ret = { id: (id + i) + '', props: { opacity: 0.6, type: 0, name: k.n, btnType }, position: {} }
        let scale = btnType === 'joystick' ? fixByGear([0.75, 0.875, 1, 1.125, 1.25], k.p.w / 380) : Number((k.p.w / 96).toFixed(3))
        ret.props.scale = scale
        if (btnType === 'keyboard' || btnType === 'joystick') {
            let { c, k: kk } = k.ks[0]
            ret.props.key = kk
            ret.props.value = c
        } else if (btnType === 'macro') {
            ret.props.key = k.ks.map(kk => kk.k).join('，')
            ret.props.value = k.ks.map(kk => kk.c + '').join('，')
        } else {
            let m = MouseBtns.find(mb => mb.value === k.ks[0].c)
            ret.props.key = m.name
            ret.props.value = m.value
        }
        let x = k.p.x * width
        let y = k.p.y * height
        let size = btnType === 'joystick' ? 1.32 : 0.334
        ret.position.x = Math.min(width - rem * scale * size, x)
        ret.position.y = Math.min(height - rem * scale * size, y)
        return ret
    })
    return data
}

export const parseOpJson = keyboard => {
    const { width, height } = getWindowSizeForLandscape()
    const data = keyboard.map(k => {
        let { position, props } = k
        let { x, y } = position
        let { btnType, key, name, scale, value } = props
        let ret = { n: name, s: 1, sT: 2, p: { x: Number((x / width).toFixed(2)), y: Number((y / height).toFixed(2)) } }
        if (btnType === 'keyboard' || btnType === 'joystick') {
            ret.kT = btnType === 'joystick'
                ? key === 70
                    ? 6
                    : 7
                : 1
            ret.ks = [{ c: value, k: key }]
        } else if (btnType === 'macro') {
            ret.kT = 2
            let keys = key.split('，')
            ret.ks = value.split('，').map((sk, i) => ({ c: Number(sk), k: keys[i] }))
        } else {
            if (value === 512) {
                ret.kT = 8
                ret.ks = [{ c: value, k: 'LMOUSE' }]
            } else if (value === 513) {
                ret.kT = 10
                ret.ks = [{ c: value, k: 'MMOUSE' }]
            } else if (value === 514) {
                ret.kT = 9
                ret.ks = [{ c: value, k: 'RMOUSE' }]
            } else if (value === -1) {
                ret.kT = 11
                ret.ks = [{ c: value, k: 'UMOUSE' }]
            } else {
                ret.kT = 12
                ret.ks = [{ c: value, k: 'DMOUSE' }]
            }
        }
        ret.p.h = ret.p.w = Math.round((btnType === 'joystick' ? 380 : 96) * scale)
        return ret
    })
    return JSON.stringify(data)
}

export const setFullKeyboardState = status => {
    window.Cloudplay.setFullKeyboardState(status)
}